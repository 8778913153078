.App {
    margin: 0 24px;
    /* display: flex;
    justify-content: center; */
    text-align: center;

    /* font-family: */
}

.app-container {
    min-width: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.btn-container {}

.btn-app-store {
    margin-right: 12px;
}

.btn-app-clip {
    margin-top: 4em;
    background-color: rgba(25, 166, 182, 1);
    padding: 1rem;
    border-radius: 0.5rem;
    border: none;
    color: white;
    &:hover,
    &:active,
    &.selected {
        background-color: rgba(25, 166, 182, 0.8);
    }
    text-decoration: none;
}
